'use client';
import React, { useState, useEffect } from 'react';
import { ArrowUp } from 'lucide-react';
import { useTranslations } from 'next-intl';

const isColorDark = (r: number, g: number, b: number) => {
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  return luminance < 0.5;
};

const getBackgroundColor = (element: Element) => {
  const backgroundColor = window.getComputedStyle(element).backgroundColor;
  const rgb = backgroundColor.match(/\d+/g);
  if (rgb) {
    const [r, g, b] = rgb.map(Number);
    return { r, g, b };
  }
  return null;
};

const ScrollTop = () => {
  const [isVisible, setIsVisible] = useState(false);
  // const [isDarkBackground, setIsDarkBackground] = useState(false);
  const t = useTranslations('Common');

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setIsVisible(true);

      const elements = document.elementsFromPoint(window.innerWidth / 2, window.innerHeight / 2);
      const closestBgTrack = elements.find(el => el.classList.contains('bg-track'));

      // if (closestBgTrack) {
      //   const { r, g, b } = getBackgroundColor(closestBgTrack) || {};
      //   if (r !== undefined && g !== undefined && b !== undefined) {
      //     setIsDarkBackground(isColorDark(r, g, b));
      //   }
      // }
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return (
    <button
      className={`scrollToTopButton group ${isVisible ? 'visible' : ''}`}
      onClick={scrollToTop}
    // style={{
    //   color: isDarkBackground ? '#FFF' : '#000',
    //   backgroundColor: isDarkBackground ? '#000' : '#FFF',
    // }}
    >
      <ArrowUp className="inline-block md:h-6 md:w-6" />
      <span className='sr-only'>{t('scrollTop')}</span>
    </button>
  );
};

export default ScrollTop;
