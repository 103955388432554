'use client';
import React from 'react';
import { Link } from '@/navigation';
import { ArrowRight } from 'lucide-react';

const CTAPrimary = ({
  text,
  href,
  mode,
  extraClass,
  icon,
  hover,
}: {
  text: string;
  href: string;
  mode?: 'light' | 'dark' | 'yellow';
  extraClass?: string;
  icon?: boolean;
  hover?: string;
}) => {

  return <Link
    href={href}
    className={
      `${mode == 'dark' ?
        `bg-black text-white ${hover ? hover : 'hover:bg-white hover:text-black'}`
        :
        `border text-white ${hover ? hover : 'hover:bg-white/90 hover:text-black'}`
      }
        transition items-center rounded-3xl px-5 py-2 text-sm ${extraClass}`
    }
  >
    {text}
    {icon ? <ArrowRight className='inline-block ml-2' strokeWidth='.75' size={22} /> : null}
  </Link>;
};

export default CTAPrimary;
