import React from "react";
import { ArrowRight } from "lucide-react";
import NavLink from "../NavLink";

const DotExpandButton = ({
  text = '[Your text here]',
  href = '#',
  style = 'default',
  isMain = false,
  target = '_self',
  onClick = () => { },
}: {
  text: string,
  href?: string,
  style?: 'default' | 'light' | 'dark' | 'lightBlue' | 'navCTA',
  isMain?: boolean,
  target?: '_self' | '_blank' | '_parent' | '_top' | string,
  onClick?: () => void,
}
) => {

  let linkClass = 'bg-white text-brand-blue-dark hover:bg-brand-yellow hover:text-brand-blue-dark';
  let dotClass = 'bg-brand-blue-dark group-hover:bg-brand-blue-dark';
  let arrowClass = 'text-brand-yellow';

  if (style === 'light') {
    linkClass = 'bg-brand-blue-dark text-white hover:bg-brand-blue-reg';
    dotClass = 'bg-white group-hover:bg-white';
    arrowClass = 'text-brand-blue-reg';
  }

  if (style === 'dark') {
    linkClass = 'bg-brand-blue-reg text-white hover:bg-brand-blue-reg';
    dotClass = 'bg-white group-hover:bg-white';
    arrowClass = 'text-brand-blue-reg';
  }

  if (style === 'lightBlue') {
    linkClass = 'bg-brand-blue-shade-1 text-white hover:bg-brand-blue-reg hover:text-white';
    dotClass = 'bg-white group-hover:bg-white';
    arrowClass = 'text-brand-blue-light';
  }

  if (style === 'navCTA') {
    linkClass = 'bg-white text-brand-blue-dark hover:bg-brand-yellow hover:text-brand-blue-dark text-xs md:text-sm !h-9 mt-0.5 pl-1';
    dotClass = 'bg-brand-blue-dark md:group-hover:bg-brand-blue-dark';
    arrowClass = 'text-brand-yellow';
  }

  return (
    <NavLink
      href={href}
      target={target}
      onClick={onClick}
      className={`group flex shrink-0 h-10 pl-1 ${isMain ? 'pr-6' : 'pr-4'} items-center gap-2 
        rounded-full text-sm transition-all duration-700 ease-in-out 
        w-fit
        ${linkClass}`}
    >
      <span
        className={`relative overflow-hidden rounded-full flex items-center
        h-0 w-0 p-1 ml-2 transition-all duration-300 md:group-hover:w-8 md:group-hover:h-8 md:group-hover:ml-0
        ${dotClass}`}
      >
        <ArrowRight
          className={`transition-all duration-300 -translate-x-[200%]
          scale-0 md:group-hover:translate-x-0 md:group-hover:scale-100 md:group-active:-rotate-45
          ${arrowClass}`}
        />
      </span>
      <span className="font-bold">{text}</span>
    </NavLink>
  );
};

export default DotExpandButton;

